import axios from "axios"

const config = {
    host: "https://jwop59v7kj.execute-api.ap-south-1.amazonaws.com/Prod",
    urls: {
        get_options: "/options",
        get_data: "/query",
        pie_charts: "/pie-charts",
        dash_table: "/dash-table-data",
        av_hic_data: "/av-hic-data",
        gtrends_hic_data: "/gtrends-hic-data",
        ibm_news_hic_data: "/ibm-news-hic-data",
        stocktwits_hic_data: "/stocktwits-hic-data",
        composite_sentiment: "/composite-sentiment",
        get_daily_graphs: "/get-daily-graphs",
        get_market_status_graphs: "/get-market-status-graphs-test",
        reddit_hic_data: "/reddit-hic-data"
    }
}

const get_tickers = () => {
    return [
        "AAPL",
        "AMZN",
        "TSLA",
        "CAT",
        "UBER",
        "JPM",
        "PFE",
        "PG",
        "MAR",
        "XOM",
        "GME",
        "GOOGL",
        "FB",
        "MSFT",
        "BA",
        "AMD",
        "NFLX",
        "NVDA",
        "BABA",
        "SQ",
        "WMT",
        "DIS",
        "F",
        "GS",
        "NVAX",
        "JNJ",
        "V",
        "PYPL",
        "BAC",
        "INTC"
    ]
}

const key = "prod"

const get_options = async (ticker, start_date, end_date, title_text) => {
    let body = await {
        ticker: ticker,
        sdate: start_date,
        edate: end_date,
        type: "title",
        key: key
    }
    const response = await axios
        .post(`${config.host}${config.urls.get_options}`, body)
        .then((response) => {
            // console.log(response, response.data_text)
            return response.data
        })
        .catch((e) => {
            console.log("Inside Error: ", e)
            return ""
        })

    const data = response.data
    let Keyword = []
    let Concept = []
    let Author = []
    let Person = []
    let Company = []

    if (data) {
        for (let d of data["Keyword"]) {
            Keyword.push({ label: d, value: d })
            // Keyword.push({ label: d, value: d.split(' ').join('_') });
        }

        for (let d of data["Concept"]) {
            Concept.push({ label: d, value: d })
        }

        for (let d of data["Author"]) {
            Author.push({ label: d, value: d })
        }

        for (let d of data["Person"]) {
            Person.push({ label: d, value: d })
        }

        for (let d of data["Company"]) {
            Company.push({ label: d, value: d })
        }
    }

    const result = {
        Author,
        Person,
        Keyword,
        Concept,
        Company
    }

    return result
}

const get_data = async (query) => {
    const sources = ["stocktwits", "ibm_news", "gtrends", "alphavantage"]
    const data = {}
    for (const source of sources) {
        const body = {
            query: query,
            source: source,
            key: key
        }
        const response = await axios
            .post(`${config.host}${config.urls.get_data}`, body)
            .then((response) => {
                return response.data
            })
            .catch((e) => {
                console.log("Inside Error: ", e)
                return ""
            })
        data[source] = response.data.data
    }
    return data
}

const get_piecharts_data = async (query, dq_flag) => {
    try {
        if (query.rules.rules[0].field === null) {
            query["rules"] = []
        }
    } catch (err) {
        query["rules"] = []
    }
    const body = {
        query: query,
        key: key,
        dq_flag: dq_flag
    }
    const response = await axios
        .post(`${config.host}${config.urls.pie_charts}`, body)
        .then((response) => {
            return response.data
        })
        .catch((e) => {
            console.log("Data Retrieval Error (Piecharts): ", e)
            return ""
        })

    const data = response.data
    return data
}

const get_dashtable_data = async (query, source, dq_flag) => {
    try {
        if (query.rules.rules[0].field === null) {
            query["rules"] = []
        }
    } catch (err) {
        query["rules"] = []
    }
    const body = {
        query: query,
        source: source,
        key: key,
        dq_flag: dq_flag
    }
    const response = await axios
        .post(`${config.host}${config.urls.dash_table}`, body)
        .then((response) => {
            return response.data
        })
        .catch((e) => {
            console.log("Data Retrieval Error (DashTable):", source, e)
            return ""
        })
    const data = response
    // console.log(response)
    return data
}

const get_av_hic_data = async (query) => {
    try {
        if (query.rules.rules[0].field === null) {
            query["rules"] = []
        }
    } catch (err) {
        query["rules"] = []
    }
    const body = {
        ticker: query["ticker"],
        sdate: query["sdate"],
        edate: query["edate"]
    }
    const response = await axios
        .post(`${config.host}${config.urls.av_hic_data}`, body)
        .then((response) => {
            return response.data
        })
        .catch((e) => {
            console.log("Data Retrieval Error (AV Hic): ", e)
            return ""
        })
    const data = response.data
    return data
}

const get_gtrends_hic_data = async (query) => {
    try {
        if (query.rules.rules[0].field === null) {
            query["rules"] = []
        }
    } catch (err) {
        query["rules"] = []
    }
    const body = {
        ticker: query["ticker"],
        sdate: query["sdate"],
        edate: query["edate"]
    }
    const response = await axios
        .post(`${config.host}${config.urls.gtrends_hic_data}`, body)
        .then((response) => {
            return response.data
        })
        .catch((e) => {
            console.log("Data Retrieval Error (GT Hic): ", e)
            return ""
        })
    const data = response.data
    return data
}

const get_ibm_news_data = async (query) => {
    try {
        if (query.rules.rules[0].field === null) {
            query["rules"] = []
        }
    } catch (err) {
        query["rules"] = []
    }
    const body = {
        query: query,
        key: key
    }
    const response = await axios
        .post(`${config.host}${config.urls.ibm_news_hic_data}`, body)
        .then((response) => {
            return response
        })
        .catch((e) => {
            console.log("Data Retrieval Error (News HIC): ", e)
            return []
        })
    const data = response.data
    return data
}

const get_stocktwits_data = async (query, dq_flag) => {
    try {
        if (query.rules.rules[0].field === null) {
            query["rules"] = []
        }
    } catch (err) {
        query["rules"] = []
    }
    const body = {
        query: query,
        key: key,
        dq_flag: dq_flag
    }
    const response = await axios
        .post(`${config.host}${config.urls.stocktwits_hic_data}`, body)
        .then((response) => {
            return response.data
        })
        .catch((e) => {
            console.log("Data Retrieval Error (Stocktwits HIC): ", e)
            return ""
        })
    const data = response.data
    return data
}

const get_composite_sentiment = async (query, dq_flag) => {
    try {
        if (query.rules.rules[0].field === null) {
            query["rules"] = []
        }
    } catch (err) {
        query["rules"] = []
    }
    const body = {
        query: query,
        key: key,
        dq_flag: dq_flag
    }
    const response = await axios
        .post(`${config.host}${config.urls.composite_sentiment}`, body)
        .then((response) => {
            return response.data
        })
        .catch((e) => {
            console.log("Data Retrieval Error (Piecharts): ", e)
            return ""
        })

    const data = response.data
    return data
}
const get_graphs = async (graph_type, date = null, ticker = null) => {
    const body = {
        graph_type: graph_type,
        key: key,
        date: date ? date : null,
        ticker
    }
    const response = await axios
        .post(`${config.host}${config.urls.get_daily_graphs}`, body)
        .then((response) => {
            // console.log(typeof(JSON.stringify(response.data)))
            return JSON.parse(
                JSON.stringify(response.data).replaceAll("NaN", null)
            )
        })
        .catch((e) => {
            return "GGG"
        })

    return JSON.parse(JSON.stringify(response).replaceAll("NaN", null))
}

const get_market_status_graphs = async ({
    graph_type,
    ticker = null,
    marketStatusDate,
    market,
    days_range
}) => {
    const body = {
        graph_type,
        key,
        ticker,
        current_date: marketStatusDate,
        days_range,
        market
    }
    console.log(
        "API Called with",
        `${config.host}${config.urls.get_market_status_graphs}`,
        body
    )

    const response = await axios
        .post(`${config.host}${config.urls.get_market_status_graphs}`, body)
        .then((response) => {
            return JSON.parse(
                JSON.stringify(response.data).replaceAll("NaN", null)
            )
        })
        .catch((error) => {
            return error
        })

    return JSON.parse(JSON.stringify(response).replaceAll("NaN", null))
}

const get_reddit_data = async (query) => {
    try {
        if (query.rules.rules[0].field === null) {
            query["rules"] = []
        }
    } catch (err) {
        query["rules"] = []
    }
    const body = {
        query: query,
        key: key
    }
    const response = await axios
        .post(`${config.host}${config.urls.reddit_hic_data}`, body)
        .then((response) => {
            console.log("REDDIT", response.data)
            return response.data
        })
        .catch((e) => {
            console.log("Data Retrieval Error (Stocktwits HIC): ", e)
            return ""
        })
    const data = response.data
    return data
}

const api = {
    get_tickers,
    get_options,
    get_data,
    get_piecharts_data,
    get_dashtable_data,
    get_av_hic_data,
    get_gtrends_hic_data,
    get_ibm_news_data,
    get_stocktwits_data,
    get_composite_sentiment,
    get_reddit_data,
    get_graphs,
    get_market_status_graphs
}

export default api
