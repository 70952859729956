import "bootstrap/dist/css/bootstrap.min.css"
import React, { useState, useEffect, useMemo, useCallback } from "react"
import { makeStyles } from "@material-ui/core/styles"
import { styled } from "@mui/material/styles"
import { Button, Modal, IconButton, Backdrop, Grid } from "@material-ui/core"
import { LogoutRounded } from "@mui/icons-material"
import InfoTwoToneIcon from "@mui/icons-material/InfoTwoTone"
import ExpandMoreIcon from "@mui/icons-material/ExpandMore"
import {
    Card,
    CardContent,
    CardHeader,
    Typography,
    ButtonGroup,
    Stack,
    Accordion,
    AccordionSummary,
    AccordionDetails
} from "@mui/material"
import { FullScreen, useFullScreenHandle } from "react-full-screen"
import fullScreen from "highcharts/modules/full-screen"
import wordCloud from "highcharts/modules/wordcloud.js"
import "highcharts/css/annotations/popup.css"
import Highcharts from "highcharts/highstock"
import HighchartsReact from "highcharts-react-official"
import FullscreenOutlinedIcon from "@mui/icons-material/FullscreenOutlined"
import Moment from "moment-timezone"
import Wordcloud from "./Wordcloud"
import indicatorsAll from "highcharts/indicators/indicators-all"
import annotationsAdvanced from "highcharts/modules/annotations-advanced"
import priceIndicator from "highcharts/modules/price-indicator"
import {
    FacebookShareButton,
    LinkedinShareButton,
    TwitterShareButton,
    WhatsappShareButton,
    TelegramShareButton,
    EmailShareButton
} from "react-share"
import {
    FacebookIcon,
    TwitterIcon,
    LinkedinIcon,
    WhatsappIcon,
    EmailIcon,
    TelegramIcon
} from "react-share"
import { auth, logout, storeShareLogs } from "./firebase"
import { useAuthState } from "react-firebase-hooks/auth"
import { useHistory, withRouter } from "react-router-dom"
import SpeedDial from "@mui/material/SpeedDial"
import dayjs from "dayjs"
import { LocalizationProvider } from "@mui/x-date-pickers"
import { DemoContainer } from "@mui/x-date-pickers/internals/demo"
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs"
import { DateTimePicker } from "@mui/x-date-pickers/DateTimePicker"
import * as customParseFormat from "dayjs/plugin/customParseFormat"
import timezone from "dayjs/plugin/timezone.js"
import utc from "dayjs/plugin/utc"
import VideoSection from "./VideoSection"
import ProductSheets from "./ProductSheets"
import Footer from "./Footer"
import FSAILogo from "./static/fsai_logo.png"
import _ from "lodash"
import "./static/graph.css"
import { Switcher } from "."
import { getGraphData } from "../utils/functions"
import { SINGLE_GRAPH_DURATION } from "../utils/constants"
import DashboardAlert from "./page/DashboardAlert"
import { marketStatusItems } from "../constants"
import dateJson from "./static/data/date.json"
import Joyride, { ACTIONS, EVENTS } from "react-joyride"
import * as alertsJSON from "../components/static/data/alerts.json"
import { generateAlerts } from "../api-utils/functions"

dayjs.extend(customParseFormat)
Highcharts.setOptions({
    lang: {
        thousandsSep: ","
    }
})
wordCloud(Highcharts)
require("highcharts/modules/exporting")(Highcharts)
indicatorsAll(Highcharts)
annotationsAdvanced(Highcharts)
priceIndicator(Highcharts)
fullScreen(Highcharts)
dayjs.extend(utc)
dayjs.extend(timezone)

const shareUrl = "https://faang.finsoftai.com"
const title =
    "Free subscription for daily social sentiment of FAANG stocks, please access"
const GRAPH_NORMALIZE_FACTOR = 20

const useStyles = makeStyles((theme) => ({
    root: {
        backgroundColor: "white !important"
    },
    icon: {
        fill: "blue"
    },
    modal: {
        display: "flex",
        width: "100%",
        alignItems: "center",
        justifyContent: "center"
    },
    paper: {
        backgroundColor: theme.palette.background.paper,
        boxShadow: theme.shadows[5],
        padding: theme.spacing(2, 3, 2),
        paddingRight: 0
    },
    fullscreenBackground: {
        border: "solid 2px red !important",
        height: "100% !important"
    },
    modalTwo: {
        margin: "auto",
        marginTop: "5%",
        flexGrow: 1,
        alignItems: "center",
        justifyContent: "center"
    },
    paper: {
        backgroundColor: theme.palette.background.paper,
        boxShadow: theme.shadows[5],
        padding: theme.spacing(2, 2, 2)
    }
}))

let initialStockOptions = {
    buzz: {
        chart: {
            type: "bar"
        },
        plotOptions: {
            series: {
                stacking: "normal"
            },
            visible: false
        },
        lang: {
            thousandsSep: ","
        },
        credits: {
            text: "finsoftai.com",
            href: "http://www.finsoftai.com",
            style: {
                fontSize: 12
            },
            position: {
                align: "right",
                x: -10
            }
        },
        stockTools: {
            gui: {
                enabled: false // disable the built-in toolbar
            }
        },
        xAxis: [
            {
                title: {
                    text: "Tickers",
                    style: { fontWeight: "bold", fontSize: "13px" }
                }
            }
        ],

        yAxis: [
            {
                title: {
                    text: "Buzz Volume",
                    style: { fontWeight: "bold", fontSize: "13px" }
                }
            }
        ],
        title: {
            text: ""
        },
        exporting: {
            buttons: {
                contextButton: {
                    menuItems: ["viewFullscreen"]
                }
            }
        },
        tooltip: {
            pointFormat: "{point.y:,.0f} "
        },
        series: [
            {
                data: []
            }
        ]
    },
    sentiment: {
        chart: {
            type: "bar",
            styledmode: "on"
        },
        credits: {
            text: "finsoftai.com",
            href: "http://www.finsoftai.com",
            style: {
                fontSize: 12
            },
            position: {
                align: "right",
                x: -10
            }
        },

        stockTools: {
            gui: {
                enabled: false
            }
        },
        lang: {
            thousandsSep: ","
        },
        tooltip: {
            pointFormat: "{point.y:,.2f} mm"
        },
        xAxis: [
            {
                title: {
                    text: "Tickers",
                    style: { fontWeight: "bold", fontSize: "13px" }
                }
            }
        ],

        yAxis: [
            {
                title: {
                    text: "sentiment",
                    style: { fontWeight: "bold", fontSize: "13px" }
                }
            }
        ],
        title: {
            text: ""
        },

        exporting: {
            buttons: {
                contextButton: {
                    menuItems: ["viewFullscreen"]
                }
            }
        },

        labels: {
            formatter: function () {
                return Highcharts.numberFormat(this.value, 2)
            }
        },
        series: [
            {
                name: "Tickers",
                data: [],
                color: "#43a84a",
                negativeColor: "#e64736",
                type: "bar",
                pointStart: 0
            }
        ]
    },
    change: {
        chart: {
            type: "bar",
            styledmode: "on"
        },
        lang: {
            thousandsSep: ","
        },
        tooltip: {
            pointFormat: "{point.y:,.0f}"
        },
        credits: {
            text: "finsoftai.com",
            href: "http://www.finsoftai.com",
            style: {
                fontSize: 12
            },
            position: {
                align: "right",
                x: -10
            }
        },
        stockTools: {
            gui: {
                enabled: false
            }
        },
        xAxis: [
            {
                title: {
                    text: "Tickers",
                    style: { fontWeight: "bold", fontSize: "13px" }
                }
            }
        ],

        yAxis: [
            {
                title: {
                    text: "sentiment",
                    style: { fontWeight: "bold", fontSize: "13px" }
                }
            }
        ],
        title: {
            text: ""
        },

        exporting: {
            buttons: {
                contextButton: {
                    menuItems: ["viewFullscreen"]
                }
            }
        },

        labels: {
            formatter: function () {
                return Highcharts.numberFormat(this.value, 2)
            }
        },
        series: [
            {
                name: "Tickers",
                data: [],
                color: "#43a84a",
                negativeColor: "#e64736",
                type: "bar",
                pointStart: 0
            }
        ]
    },
    buzz_change: {
        chart: {
            type: "bar",
            styledmode: "on"
        },
        credits: {
            text: "finsoftai.com",
            href: "http://www.finsoftai.com",
            style: {
                fontSize: 12
            },
            position: {
                align: "right",
                x: -10
            }
        },
        stockTools: {
            gui: {
                enabled: false
            }
        },
        lang: {
            thousandsSep: ","
        },
        tooltip: {
            pointFormat: "{point.y:,.2f} mm"
        },
        xAxis: [
            {
                title: {
                    text: "Tickers",
                    style: { fontWeight: "bold", fontSize: "13px" }
                }
            }
        ],

        yAxis: [
            {
                title: {
                    text: "buzz",
                    style: { fontWeight: "bold", fontSize: "13px" }
                }
            }
        ],
        title: {
            text: ""
        },

        exporting: {
            buttons: {
                contextButton: {
                    menuItems: ["viewFullscreen"]
                }
            }
        },

        labels: {
            formatter: function () {
                return Highcharts.numberFormat(this.value, 2)
            }
        },
        series: [
            {
                name: "Tickers",
                data: [],
                color: "#43a84a",
                negativeColor: "#e64736",
                type: "bar",
                pointStart: 0
            }
        ]
    }
}

const sortSeries = (data1, data2, key) => {
    let multiplier =
        (key === "sentiment") | (key === "sentimentChange") ? 0.5 : 1

    const dataList = Object.keys(data1).map((key) => {
        return {
            value: multiplier * data1[key] + multiplier * data2[key],
            name: key,
            d1: data1[key],
            d2: data2[key]
        }
    })

    dataList.sort((a, b) => (a.value < b.value ? 1 : -1)).splice(30, 1)

    // if (key === "buzz") {
    //     //sortSeries

    //     let tickerList = dataList.map((t) => t.name)
    //     tickerList = tickerList.filter((item) => {
    //         return item !== "fields"
    //     })
    //     return tickerList
    // }
    if (key === "buzzChange") {
        let tickerList = dataList.map((t) => t.name)
        let topTickerList = tickerList
            .slice(0, 5)
            .concat(tickerList.slice(25, 30))
        tickerList = tickerList.filter((item) => {
            return item !== "fields"
        })
        topTickerList = topTickerList.filter((item) => {
            return item !== "fields"
        })

        return { all: tickerList, top: topTickerList }
    } else if (
        (key === "buzz") |
        (key === "sentiment") |
        (key === "sentimentChange")
    ) {
        //sortSentimentSeries
        let tickerList = dataList.map((t) => t.name)
        let topTickerList = tickerList
            .slice(0, 5)
            .concat(tickerList.slice(25, 30))
        tickerList = tickerList.filter((item) => {
            return item !== "fields"
        })
        topTickerList = topTickerList.filter((item) => {
            return item !== "fields"
        })

        return { all: tickerList, top: topTickerList }
    }
}

const mapStockDataByDuration = (durationData, stock) => {
    if (stock !== "FB/META") return durationData[stock]
    else return durationData["META"]
}

const getSeries = (data1, data2, data3, data4, source, tickerList) => {
    let toggle = "all"
    try {
        let temp = tickerList[toggle].map((key) => {
            return {
                y: parseFloat((0.5 * data1[key] + 0.5 * data2[key]) / 1),
                stPercent: data2[key],
                newsPercent: data1[key],
                newsAbs: data3[key],
                stAbs: data4[key]
            }
        })
        return {
            name: source,
            type: "bar",
            data: temp.slice(0, 11),
            pointStart: 0,
            color: "#43a84a",
            negativeColor: "#e64736"
        }
    } catch (r) {
        let temp = tickerList[toggle].map((key) => {
            return {
                y: parseFloat((0.5 * data1[key] + 0.5 * data2[key]) / 1),
                st: data2[key],
                news: data1[key]
            }
        })
        return {
            name: source,
            type: "bar",
            data: temp.slice(0, 11),
            pointStart: 0,
            color: "#43a84a",
            negativeColor: "#e64736"
        }
    }
}

function getSeriesTwo(data1, data2, source, tickerList) {
    let toggle = "all"
    let temp = tickerList[toggle].map((key) => {
        return {
            y: parseFloat(data1[key] + data2[key] * GRAPH_NORMALIZE_FACTOR),
            st: data2[key] * GRAPH_NORMALIZE_FACTOR,
            news: data1[key]
        }
    })
    return {
        name: source,
        type: "bar",
        data: temp.slice(0, 11),
        pointStart: 0,
        color: "#43a84a",
        negativeColor: "#e64736"
    }
}

const findSeries = (Sname, data, changeFlag) => {
    let data1 = null
    let data2 = null
    let data3 = null
    let data4 = null
    if (changeFlag) {
        data1 = data.find((o) => o.fields === Sname + "_st_change_percent")
        data2 = data.find((o) => o.fields === Sname + "_news_change_percent")
        data3 = data.find((o) => o.fields === Sname + "_st_change")
        data4 = data.find((o) => o.fields === Sname + "_news_change")
        return { news: data2, st: data1, newsAbs: data4, stAbs: data3 }
    } else {
        data1 = data.find((o) => o.fields === Sname + "_st")
        data2 = data.find((o) => o.fields === Sname + "_news")

        return { news: data2, st: data1 }
    }
}

const getFaangStocks = (data = {}) => {
    // Use optional chaining and the nullish coalescing operator to handle missing properties
    data["FB/META"] = data?.FB || data?.META

    const tickers = ["FB/META", "AAPL", "AMZN", "NFLX", "GOOGL", "fields"]
    let finalObj = tickers.reduce((obj, cur) => {
        if (data[cur] !== undefined) {
            // Check if the property exists
            obj[cur] = data[cur]
        }
        return obj
    }, {})

    return finalObj
}

const StyledSpeedDial = styled(SpeedDial)(({ theme }) => ({
    // position: 'absolute
    "&.MuiSpeedDial-directionUp, &.MuiSpeedDial-directionLeft": {
        bottom: theme.spacing(2),
        right: theme.spacing(2)
    },
    "&.MuiSpeedDial-directionDown, &.MuiSpeedDial-directionRight": {
        top: theme.spacing(2),
        borderRadius: 5,
        left: theme.spacing(2)
    }
}))

const actions = [
    {
        icon: (
            <div className='mt-3'>
                <IconButton
                    size='small'
                    onClick={() => {
                        shareButtonLogs("Facebook")
                    }}>
                    <FacebookShareButton
                        url={shareUrl}
                        quote={title}
                        className='Demo__some-network__share-button'>
                        <FacebookIcon size={30} round />
                    </FacebookShareButton>
                </IconButton>
            </div>
        ),
        name: "Facebook"
    },
    {
        icon: (
            <div className='mt-3'>
                <IconButton
                    size='small'
                    onClick={() => {
                        shareButtonLogs("Twitter")
                    }}>
                    <TwitterShareButton
                        url={shareUrl}
                        title={title}
                        className='Demo__some-network__share-button'>
                        <TwitterIcon size={30} round />
                    </TwitterShareButton>
                </IconButton>
            </div>
        ),
        name: "Twitter"
    },
    {
        icon: (
            <div className='mt-3'>
                <IconButton
                    size='small'
                    onClick={() => {
                        shareButtonLogs("Whatsapp")
                    }}>
                    <WhatsappShareButton
                        url={shareUrl}
                        title={title}
                        separator=''
                        className='Demo__some-network__share-button'>
                        <WhatsappIcon size={30} round />
                    </WhatsappShareButton>
                </IconButton>
            </div>
        ),
        name: "Whatsapp"
    },
    {
        icon: (
            <div className='mt-3'>
                <IconButton
                    size='small'
                    onClick={() => {
                        shareButtonLogs("Telegram")
                    }}>
                    <TelegramShareButton
                        url={shareUrl}
                        title={title}
                        separator=''
                        className='Demo__some-network__share-button'>
                        <TelegramIcon size={30} round />
                    </TelegramShareButton>
                </IconButton>
            </div>
        ),
        name: "Telegram"
    },
    {
        icon: (
            <div className='mt-3'>
                <IconButton
                    size='small'
                    onClick={() => {
                        shareButtonLogs("Linkedin")
                    }}>
                    <LinkedinShareButton
                        url={shareUrl}
                        className='Demo__some-network__share-button'>
                        <LinkedinIcon size={30} round />
                    </LinkedinShareButton>
                </IconButton>
            </div>
        ),
        name: "LinkedIn"
    },
    {
        icon: (
            <div className='mt-3'>
                <IconButton
                    size='small'
                    onClick={() => {
                        shareButtonLogs("Email")
                    }}>
                    <EmailShareButton
                        url={shareUrl}
                        // onClick={ ()=>{shareButtonLogs("EMAIL")}}
                        subject={"FAANG Dashboards"}
                        body='Free subscription for daily social sentiment of FAANG stocks, please access '
                        className='Demo__some-network__share-button'>
                        <EmailIcon size={30} round />
                    </EmailShareButton>
                </IconButton>
            </div>
        ),
        name: "Email"
    }
]

const shareButtonLogs = (source) => {
    storeShareLogs(source)
}

const useGraphUpdate = (graphData, graphViews, key, processFunction) => {
    useEffect(() => {
        if (graphData[key]) {
            processFunction(graphData[key])
        }
    }, [graphData[key], graphViews[key], processFunction])
}

const Graphs = (props) => {
    const [user, loading, error] = useAuthState(auth)
    const history = useHistory()

    const classes = useStyles()
    const [graphModalToggle, setGraphToggle] = useState(true)
    const [graphViews, setGraphViews] = useState({
        buzz: "one_day",
        sentiment: "one_day",
        changeBuzz: "one_day",
        changeSentiment: "one_day"
    })
    const [graphData, setGraphData] = useState({
        buzz: null,
        sentiment: null,
        changeBuzz: null,
        changeSentiment: null
    })
    const [buzzGraph, setBuzzGraph] = useState(initialStockOptions["buzz"])
    const [buzzGraphChange, setBuzzGraphChange] = useState(
        initialStockOptions["buzz_change"]
    )
    const [sentiGraph, setSentiGraph] = useState(
        initialStockOptions["sentiment"]
    )
    const [sentiGraphChange, setSentiGraphChange] = useState(
        initialStockOptions["change"]
    )
    const [datesList, setDatesList] = useState([])
    const [displayDates, setDisplayDates] = useState({
        buzz: "",
        sentiment: "",
        changeBuzz: "",
        changeSentiment: ""
    })
    const [steps, setSteps] = useState([
        {
            target: ".step-one",
            content: (
                <span>
                    Welcome to the FAANG Sentiment Dashboard! Daily updates,
                    generated an hour before the market opens. Make informed
                    investment decisions using sentiment trend analysis and
                    trending keywords. Analyze sentiment changes pre-market for
                    winning trades using alternate date, and assess social and
                    credit risk for FAANG companies.
                </span>
            ),
            disableBeacon: true,
            placement: "top"
        },

        {
            target: ".step-two",
            content: (
                <div>
                    <span>
                        Explore the Change in Sentiment Dashboard, illustrating
                        average sentiment shifts over various periods compared
                        to today's opening. Hover over the graph for tooltips
                        detailing sentiment changes from news and social media
                        sources.
                    </span>
                </div>
            ),
            placement: "top"
        },
        {
            target: ".step-three",
            content: (
                <div>
                    <span>
                        Click on predefined durations like 7 days to see
                        sentiment changes ((Today's Sentiment - Average
                        Sentiment Over 7 Days) / Average Sentiment Over 7 Days)
                        * 100. Gain insights into sentiment trends over specific
                        timeframes.
                    </span>
                </div>
            ),
            placement: "top"
        },
        {
            target: ".step-four",
            content: (
                <div>
                    <span>
                        View graphs in full screen for a detailed analysis.
                        Press escape to easily return to the normal view.
                    </span>
                </div>
            ),
            placement: "top"
        },
        {
            target: ".step-five",
            content: (
                <div>
                    <span>
                        Click on any bar graph to switch to a single stock view,
                        displaying percentage sentiment changes for the selected
                        stock over various durations. Return to the default view
                        by clicking on any bar graph again.
                    </span>
                </div>
            ),
            placement: "top"
        },
        {
            target: ".step-six",
            content: (
                <div>
                    <span>
                        Discover the Word Cloud, showcasing top trending
                        keywords, co-mentioned companies, persons, and authors
                        for the selected Ticker, Duration and Source (News or
                        Social Media). You can hover over the word cloud to see
                        additional details. Uncover valuable insights into
                        market sentiment.
                    </span>
                </div>
            ),
            placement: "top"
        },
        {
            target: ".step-seven",
            content: (
                <div>
                    <span>
                        Click on entities to toggle word clouds on/off for a
                        focused analysis. Customize your view for a more
                        tailored and insightful experience.
                    </span>
                </div>
            ),
            placement: "top"
        }
    ])

    const [run, setRun] = useState(false)

    const handleJoyrideClose = (data) => {
        const { action, index, status, type } = data
        if ([EVENTS.TOUR_END].includes(type) || action === "close") {
            // setFlag((T)=>{return true;});
            setRun((t) => {
                return false
            })
            setIndex((t) => {
                return 0
            })
        } else if (
            [EVENTS.STEP_AFTER, EVENTS.TARGET_NOT_FOUND].includes(type)
        ) {
            // Update state to advance the tour
            setIndex((prevState) => {
                return index + (action === ACTIONS.PREV ? -1 : 1)
            })
        }
        if (data.action === "close" && data.type === "step:after") {
            setRun((t) => {
                return false
            })
        }
    }
    const [stepIndex, setIndex] = useState(0)
    const screen1 = useFullScreenHandle()
    const screen2 = useFullScreenHandle()
    const screen3 = useFullScreenHandle()
    const screen4 = useFullScreenHandle()
    const [btnGroups, setbtnGroups] = useState([1, 1, 1, 1])
    const [updatedDate, setUpdatedDate] = useState(null)
    const [graphDateTime, setGraphDateTime] = useState(null)
    const [displayDate, setDisplayDate] = useState(null)
    const [dateChangeModal, setDateChangeModal] = useState(false)
    const [isLoading, setIsLoading] = useState(false)
    const [marketStatusKey, setMarketStatusKey] = useState(
        marketStatusItems[0].value
    )
    const [alerts, setAlerts] = useState(alertsJSON)

    const setViews = (key, date) => {
        let datesKVPair = {
            one_day: datesList[0],
            one_week: datesList[2],
            two_week: datesList[3],
            one_month: datesList[4],
            two_month: datesList[5]
        }
        let datesKVPairChange = {
            one_day: datesList[0],
            one_week: datesList[2],
            two_week: datesList[3],
            one_month: datesList[4],
            two_month: datesList[5]
        }
        if (key === 1) {
            setGraphViews((prevState) => {
                return { ...prevState, buzz: date }
            })
            setDisplayDates((prevState) => {
                return {
                    ...prevState,
                    buzz:
                        Moment(datesKVPair[date]).format("DD/MMMM/YYYY") +
                        " > " +
                        Moment(datesList[1]).format("DD/MMMM/YYYY")
                }
            })
        } else if (key === 2) {
            setGraphViews((prevState) => {
                return { ...prevState, sentiment: date }
            })
            setDisplayDates((prevState) => {
                return {
                    ...prevState,
                    sentiment:
                        Moment(datesKVPair[date]).format("DD/MMMM/YYYY") +
                        " > " +
                        Moment(datesList[1]).format("DD/MMMM/YYYY")
                }
            })
        } else if (key === 3) {
            setGraphViews((prevState) => {
                return { ...prevState, changeBuzz: date }
            })
            setDisplayDates((prevState) => {
                return {
                    ...prevState,
                    changeBuzz:
                        Moment(datesKVPairChange[date]).format("DD/MMMM/YYYY") +
                        " > " +
                        Moment(datesList[1]).format("DD/MMMM/YYYY")
                }
            })
        } else if (key === 4) {
            setGraphViews((prevState) => {
                return { ...prevState, changeSentiment: date }
            })
            setDisplayDates((prevState) => {
                return {
                    ...prevState,
                    changeSentiment:
                        Moment(datesKVPairChange[date]).format("DD/MMMM/YYYY") +
                        " > " +
                        Moment(datesList[1]).format("DD/MMMM/YYYY")
                }
            })
        }
    }

    useEffect(() => {
        let date = graphDateTime ?? dateJson["lastPulledDate"]
        date = dayjs(date).format("YYYY-MM-DDTHH:mm:ssZ")

        const displayDate = Moment(date)
            .hour(8)
            .minute(30)
            .format("MM/DD/YYYY HH:mm A")
        setDisplayDate(displayDate)
    }, [graphDateTime])

    const checkFutureData = (selectedDate) => {
        const tempDate = dayjs(selectedDate).hour(8).minute(30)
        const currentDate = dayjs().tz("America/New_York")

        if (tempDate.diff(currentDate, "days", true) < 0) {
            setGraphDateTime(tempDate)
        } else {
            alert("Enter a valid date")
        }
    }

    const topBar = (key) => {
        let KVPair = ["buzz", "sentiment", "changeBuzz", "changeSentiment"]
        return (
            <div className='row mb-2 ' style={{}}>
                <ButtonGroup
                    className='col-md-12'
                    style={{
                        textAlign: "left",
                        marginLeft: "1px !important",
                        marginRight: "auto",
                        paddingRight: "auto"
                    }}
                    size='small'
                    aria-label='text button group'>
                    <Button
                        style={{
                            backgroundColor:
                                btnGroups[key - 1] === 1
                                    ? "#4b8fcd"
                                    : "lightGrey",
                            color: btnGroups[key - 1] === 1 ? "white" : "black"
                        }}
                        size='small'
                        variant='contained'
                        onClick={() => {
                            setViews(key, "one_day")
                            let temp = btnGroups
                            temp[key - 1] = 1
                            setbtnGroups((prevState) => {
                                return temp
                            })
                        }}>
                        1 day
                    </Button>
                    <Button
                        style={{
                            backgroundColor:
                                btnGroups[key - 1] === 2
                                    ? "#4b8fcd"
                                    : "lightGrey",
                            color: btnGroups[key - 1] === 2 ? "white" : "black"
                        }}
                        size='small'
                        variant='contained'
                        onClick={() => {
                            setViews(key, "one_week")
                            let temp = btnGroups
                            temp[key - 1] = 2
                            setbtnGroups((prevState) => {
                                return temp
                            })
                        }}>
                        7 days
                    </Button>
                    <Button
                        style={{
                            backgroundColor:
                                btnGroups[key - 1] === 3
                                    ? "#4b8fcd"
                                    : "lightGrey",
                            color: btnGroups[key - 1] === 3 ? "white" : "black"
                        }}
                        size='small'
                        variant='contained'
                        onClick={() => {
                            setViews(key, "two_week")
                            let temp = btnGroups
                            temp[key - 1] = 3
                            setbtnGroups((prevState) => {
                                return temp
                            })
                        }}>
                        14 days
                    </Button>
                    <Button
                        style={{
                            backgroundColor:
                                btnGroups[key - 1] === 4
                                    ? "#4b8fcd"
                                    : "lightGrey",
                            color: btnGroups[key - 1] === 4 ? "white" : "black"
                        }}
                        size='small'
                        variant='contained'
                        onClick={() => {
                            setViews(key, "one_month")
                            let temp = btnGroups
                            temp[key - 1] = 4
                            setbtnGroups((prevState) => {
                                return temp
                            })
                        }}>
                        30 days
                    </Button>
                    {/* <Button style={{ backgroundColor: (btnGroups[key - 1] === 5 ? "#4b8fcd" : "lightGrey"), color: (btnGroups[key - 1] === 5 ? "white" : "black") }} size="small" variant="contained" onClick={() => { setViews(key, "two_month"); let temp = btnGroups; temp[key - 1] = 5; setbtnGroups((prevState) => { return temp }) }}>60 days</Button> */}
                </ButtonGroup>
            </div>
        )
    }

    const getSingleAbsoluteGraphData = (data, stock) => {
        const labels = [
            "one_day_st",
            "one_week_st",
            "two_week_st",
            "one_month_st",
            "one_day_news",
            "one_week_news",
            "two_week_news",
            "one_month_news"
        ]

        const dataByDuration = data.filter((durationData) =>
            labels.includes(durationData.fields)
        )

        if (stock) {
            const stStockDataByDuration = dataByDuration
                .map((durationData) => {
                    if (
                        durationData.fields === "one_day_st" ||
                        durationData.fields === "one_week_st" ||
                        durationData.fields === "two_week_st" ||
                        durationData.fields === "one_month_st"
                    ) {
                        return mapStockDataByDuration(durationData, stock)
                    }
                })
                .filter((item) => item === 0 || item)

            const newsStockDataByDuration = dataByDuration
                .map((durationData) => {
                    if (
                        durationData.fields === "one_day_news" ||
                        durationData.fields === "one_week_news" ||
                        durationData.fields === "two_week_news" ||
                        durationData.fields === "one_month_news"
                    ) {
                        return mapStockDataByDuration(durationData, stock)
                    }
                })
                .filter((item) => item === 0 || item)

            return [...stStockDataByDuration, ...newsStockDataByDuration]
        }

        return dataByDuration
    }

    const getSingleChangeGraphData = (data, stock) => {
        const labels = [
            "one_day_st_change_percent",
            "one_week_st_change_percent",
            "two_week_st_change_percent",
            "one_month_st_change_percent",
            "one_day_news_change_percent",
            "one_week_news_change_percent",
            "two_week_news_change_percent",
            "one_month_news_change_percent"
        ]

        const dataByDuration = data.filter((durationData) =>
            labels.includes(durationData.fields)
        )

        if (stock) {
            const stStockDataByDuration = dataByDuration
                .map((durationData) => {
                    if (
                        durationData.fields === "one_day_st_change_percent" ||
                        durationData.fields === "one_week_st_change_percent" ||
                        durationData.fields === "two_week_st_change_percent" ||
                        durationData.fields === "one_month_st_change_percent"
                    ) {
                        return mapStockDataByDuration(durationData, stock)
                    }
                })
                .filter((item) => item === 0 || item)

            const newsStockDataByDuration = dataByDuration
                .map((durationData) => {
                    if (
                        durationData.fields === "one_day_news_change_percent" ||
                        durationData.fields ===
                            "one_week_news_change_percent" ||
                        durationData.fields ===
                            "two_week_news_change_percent" ||
                        durationData.fields === "one_month_news_change_percent"
                    ) {
                        return mapStockDataByDuration(durationData, stock)
                    }
                })
                .filter((item) => item === 0 || item)

            return [...stStockDataByDuration, ...newsStockDataByDuration]
        }

        return dataByDuration
    }

    const drawBuzzGraph = (arrB, tickerList, response) => {
        setBuzzGraph(() => {
            return {
                chart: {
                    type: "bar",
                    borderColor: "#c0c0c0",
                    borderWidth: 1.25,
                    borderRadius: 5
                },
                lang: {
                    thousandsSep: ","
                },
                colors: ["#43a84a", "#f5d561"],
                legend: {
                    enabled: false,
                    title: {
                        text: "",
                        style: {
                            fontStyle: "italic"
                        }
                    }
                },
                plotOptions: {
                    series: {
                        stacking: "normal",
                        cursor: "pointer",
                        events: {
                            click: function (event) {
                                getSingleBuzzGraphView(
                                    event.point,
                                    tickerList,
                                    arrB,
                                    response
                                )
                            }
                        }
                    }
                },
                credits: {
                    text: "finsoftai.com",
                    href: "http://www.finsoftai.com",
                    style: {
                        fontSize: 12
                    },
                    position: {
                        align: "right",
                        x: -10
                    }
                },
                stockTools: {
                    gui: {
                        enabled: false
                    }
                },
                xAxis: [
                    {
                        title: {
                            text: "Tickers",
                            style: {
                                fontWeight: "bold",
                                fontSize: "17px",
                                fontFamily: "Poppins"
                            }
                        },
                        categories: tickerList,
                        labels: {
                            style: {
                                fontFamily: "Poppins",
                                fontWeight: "bold",
                                color: "#A8A8A8"
                            }
                        }
                    }
                ],

                yAxis: [
                    {
                        title: {
                            text: "Average Social Buzz",
                            style: {
                                fontWeight: "bold",
                                fontSize: "17px",
                                fontFamily: "Poppins"
                            }
                        }
                    }
                ],
                title: {
                    text: ""
                },
                exporting: {
                    buttons: {
                        contextButton: {
                            menuItems: ["viewFullscreen"]
                        }
                    }
                },
                tooltip: {
                    useHTML: true,
                    headerFormat:
                        '<div style="text-align:center;">  {point.x:,.0f} </div>',
                    thousandsSep: ",",
                    pointFormat:
                        '<div style="text-align:center;"><b>Buzz volume: {point.y:,.1f}</b><p>Social Media:{point.st:,.2f} | News:{point.news:,.2f} </p></div>'
                },

                series: arrB
            }
        })
    }

    const getSingleBuzzGraphData = (arr) => {
        // Check if the array has an odd length
        if (arr.length % 2 !== 0) {
            return "Array length must be even."
        }

        // Find the middle index
        const middleIndex = arr.length / 2

        // Divide the array into two halves
        const firstHalf = arr.slice(0, middleIndex)
        const secondHalf = arr.slice(middleIndex)

        const result = {
            name: "Score",
            type: "bar",
            pointStart: 0,
            data: firstHalf.map((prevIndex, currIndex) => {
                const st = firstHalf[currIndex] * GRAPH_NORMALIZE_FACTOR
                const news = secondHalf[currIndex]
                const y = st + news

                return {
                    y,
                    st,
                    news
                }
            }),
            color: "#43a84a",
            negativeColor: "#e64736"
        }

        return result
    }

    const getSingleBuzzGraphView = (
        { category },
        tickerList,
        arrB,
        response
    ) => {
        const stockDataByDuration = getSingleAbsoluteGraphData(
            response,
            category
        )

        const singleGraphData = getSingleBuzzGraphData(stockDataByDuration)

        setBuzzGraph(() => {
            return {
                chart: {
                    type: "bar",
                    borderColor: "#c0c0c0",
                    borderWidth: 1.25,
                    borderRadius: 5
                },
                lang: {
                    thousandsSep: ","
                },
                colors: ["#43a84a", "#f5d561"],
                legend: {
                    enabled: false,
                    title: {
                        text: "",
                        style: {
                            fontStyle: "italic"
                        }
                    }
                },
                plotOptions: {
                    series: {
                        stacking: "normal",
                        cursor: "pointer",
                        events: {
                            click: function () {
                                drawBuzzGraph(arrB, tickerList, response)
                            }
                        }
                    }
                },
                credits: {
                    text: "finsoftai.com",
                    href: "http://www.finsoftai.com",
                    style: {
                        fontSize: 12
                    },
                    position: {
                        align: "right",
                        x: -10
                    }
                },
                stockTools: {
                    gui: {
                        enabled: false
                    }
                },
                xAxis: [
                    {
                        title: {
                            text: category,
                            style: {
                                fontWeight: "bold",
                                fontSize: "17px",
                                fontFamily: "Poppins"
                            }
                        },
                        categories: SINGLE_GRAPH_DURATION,
                        labels: {
                            style: {
                                fontFamily: "Poppins",
                                fontWeight: "bold",
                                color: "#A8A8A8"
                            }
                        }
                    }
                ],

                yAxis: [
                    {
                        title: {
                            text: "Average Social Buzz",
                            style: {
                                fontWeight: "bold",
                                fontSize: "17px",
                                fontFamily: "Poppins"
                            }
                        }
                    }
                ],
                title: {
                    text: ""
                },
                exporting: {
                    buttons: {
                        contextButton: {
                            menuItems: ["viewFullscreen"]
                        }
                    }
                },
                tooltip: {
                    useHTML: true,
                    headerFormat:
                        '<div style="text-align:center;">  {point.x:,.0f} </div>',
                    thousandsSep: ",",
                    pointFormat:
                        '<div style="text-align:center;"><b>Buzz volume: {point.y:,.1f}</b><p>Social Media:{point.st:,.2f} | News:{point.news:,.2f} </p></div>'
                },

                series: singleGraphData
            }
        })
    }

    const drawSentimentGraph = (arrB, tickerList, toggle, response) => {
        setSentiGraph(() => {
            return {
                chart: {
                    type: "bar",
                    borderColor: "#c0c0c0",
                    borderWidth: 1.25,
                    borderRadius: 5
                },
                colors: ["#43a84a", "#f5d561"],
                legend: {
                    enabled: false
                },
                plotOptions: {
                    series: {
                        stacking: "normal",
                        cursor: "pointer",
                        events: {
                            click: function (event) {
                                getSingleSentimentGraphView(
                                    event.point,
                                    tickerList,
                                    arrB,
                                    toggle,
                                    response
                                )
                            }
                        }
                    }
                    // visible: false,
                },
                credits: {
                    text: "finsoftai.com",
                    href: "http://www.finsoftai.com",
                    style: {
                        fontSize: 12
                    },
                    position: {
                        align: "right",
                        x: -10
                    }
                },
                stockTools: {
                    gui: {
                        enabled: false
                    }
                },
                xAxis: [
                    {
                        title: {
                            text: "Tickers",
                            style: {
                                fontWeight: "bold",
                                fontSize: "15px",
                                fontFamily: "Poppins"
                            }
                        },
                        categories: tickerList[toggle],
                        plotLines: [
                            {
                                value: 4.5,
                                width: 1,
                                color: "#aaa",
                                zIndex: 10
                            }
                        ],
                        labels: {
                            style: {
                                fontFamily: "Poppins",
                                fontWeight: "bold",
                                color: "#A8A8A8"
                            }
                        }
                    }
                ],
                // legend:{enabled:false},
                yAxis: [
                    {
                        title: {
                            text: "Average Social Sentiment Score",
                            style: {
                                fontWeight: "bold",
                                fontSize: "15px",
                                fontFamily: "Poppins"
                            }
                        }
                    }
                ],
                title: {
                    text: ""
                },

                exporting: {
                    buttons: {
                        contextButton: {
                            menuItems: ["viewFullscreen"]
                        }
                    }
                },
                tooltip: {
                    useHTML: true,
                    headerFormat:
                        '<div style="text-align:center;">  {point.x:,.0f} </div>',
                    thousandsSep: ",",
                    pointFormat:
                        '<div style="text-align:center;"><b>Social Sentiment: {point.y:,.3f}</b><p>Social Media:{point.st:,.3f} | News:{point.news:,.3f} </p></div>'
                    // valueDecimal:1,
                    // valueDecimal:1,
                },
                series: arrB
            }
        })
    }

    const getSingleSentimentGraphView = (
        { category },
        tickerList,
        arrB,
        toggle,
        response
    ) => {
        const stockDataByDuration = getSingleAbsoluteGraphData(
            response,
            category
        )

        const singleGraphData = {
            name: "Score",
            type: "bar",
            pointStart: 0,
            data: stockDataByDuration
                .slice(0, stockDataByDuration.length / 2)
                .map((prevIndex, currIndex) => ({
                    y:
                        (prevIndex +
                            stockDataByDuration.slice(
                                stockDataByDuration.length / 2
                            )[currIndex]) /
                        2,
                    st: prevIndex,
                    news: stockDataByDuration.slice(
                        stockDataByDuration.length / 2
                    )[currIndex]
                })),
            color: "#43a84a",
            negativeColor: "#e64736"
        }

        setSentiGraph(() => {
            return {
                chart: {
                    type: "bar",
                    borderColor: "#c0c0c0",
                    borderWidth: 1.25,
                    borderRadius: 5
                },
                legend: {
                    enabled: false
                },
                plotOptions: {
                    series: {
                        stacking: "normal",
                        cursor: "pointer",
                        events: {
                            click: function (event) {
                                drawSentimentGraph(
                                    arrB,
                                    tickerList,
                                    toggle,
                                    response
                                )
                            }
                        }
                    }
                    // visible: false,
                },
                credits: {
                    text: "finsoftai.com",
                    href: "http://www.finsoftai.com",
                    style: {
                        fontSize: 12
                    },
                    position: {
                        align: "right",
                        x: -10
                    }
                },
                stockTools: {
                    gui: {
                        enabled: false
                    }
                },
                xAxis: [
                    {
                        title: {
                            text: category,
                            style: {
                                fontWeight: "bold",
                                fontSize: "15px",
                                fontFamily: "Poppins"
                            }
                        },
                        categories: SINGLE_GRAPH_DURATION,
                        plotLines: [
                            {
                                value: 4.5,
                                width: 1,
                                color: "#aaa",
                                zIndex: 10
                            }
                        ],
                        labels: {
                            style: {
                                fontFamily: "Poppins",
                                fontWeight: "bold",
                                color: "#A8A8A8"
                            }
                        }
                    }
                ],
                // legend:{enabled:false},
                yAxis: [
                    {
                        title: {
                            text: "Average Social Sentiment Score",
                            style: {
                                fontWeight: "bold",
                                fontSize: "15px",
                                fontFamily: "Poppins"
                            }
                        }
                    }
                ],
                title: {
                    text: ""
                },

                exporting: {
                    buttons: {
                        contextButton: {
                            menuItems: ["viewFullscreen"]
                        }
                    }
                },
                tooltip: {
                    useHTML: true,
                    headerFormat:
                        '<div style="text-align:center;">  {point.x:,.0f} </div>',
                    thousandsSep: ",",
                    pointFormat:
                        '<div style="text-align:center;"><b>Social Sentiment: {point.y:,.3f}</b><p>Social Media:{point.st:,.3f} | News:{point.news:,.3f} </p></div>'
                    // valueDecimal:1,
                    // valueDecimal:1,
                },
                series: singleGraphData
            }
        })
    }

    const drawBuzzChangeGraph = (arrBTWO, tickerList, toggle, response) => {
        setBuzzGraphChange(() => {
            return {
                chart: {
                    type: "bar",
                    borderColor: "#c0c0c0",
                    borderWidth: 1.25,
                    borderRadius: 5,
                    styledmode: "on"
                },
                plotOptions: {
                    series: {
                        stacking: "normal",
                        cursor: "pointer",
                        events: {
                            click: function (event) {
                                getSingleBuzzChangeGraphView(
                                    event.point,
                                    tickerList,
                                    arrBTWO,
                                    toggle,
                                    response
                                )
                            }
                        }
                    }
                },
                legend: {
                    enabled: false
                },
                colors: ["#43a84a", "#f5d561"],
                lang: {
                    thousandsSep: ","
                },
                credits: {
                    text: "finsoftai.com",
                    href: "http://www.finsoftai.com",
                    style: {
                        fontSize: 12
                    },
                    position: {
                        align: "right",
                        x: -10
                    }
                },
                stockTools: {
                    gui: {
                        enabled: false
                    }
                },
                xAxis: [
                    {
                        title: {
                            text: "Tickers",
                            style: {
                                fontWeight: "bold",
                                fontSize: "15px",
                                fontFamily: "Poppins"
                            }
                        },
                        categories: tickerList[toggle],
                        plotLines: [
                            {
                                value: 4.5,
                                width: 1,
                                color: "#aaa",
                                zIndex: 10
                            }
                        ],
                        labels: {
                            style: {
                                fontFamily: "Poppins",
                                fontWeight: "bold",
                                color: "#A8A8A8"
                            }
                        }
                    }
                ],
                // legend:{enabled:false},
                yAxis: [
                    {
                        title: {
                            text: "Average Change in Social Buzz",
                            style: {
                                fontWeight: "bold",
                                fontSize: "15px",
                                fontFamily: "Poppins"
                            }
                        },
                        labels: {
                            formatter: function () {
                                return this.value + "%"
                            }
                        }
                    }
                ],
                title: {
                    text: ""
                },

                exporting: {
                    buttons: {
                        contextButton: {
                            menuItems: ["viewFullscreen"]
                        }
                    }
                },

                tooltip: {
                    useHTML: true,
                    headerFormat:
                        '<div style="text-align:center;">  {point.x:,.0f} </div>',
                    thousandsSep: ",",
                    pointFormat:
                        '<div style="text-align:center;"><b>Change in Buzz volume : {point.y:,.2f}%</b><p>Social Media : {point.stPercent:,.2f}% | News : {point.newsPercent:,.2f}%</p></div>', // <br></br>Social Media value: {point.stAbs:,.2f} | News value: {point.newsAbs:,.2f}
                    // valueDecimal:1,
                    padding: 4
                },
                series: arrBTWO
            }
        })
    }

    const getSingleBuzzChangeGraphView = (
        { category },
        tickerList,
        arrB,
        toggle,
        response
    ) => {
        const stockDataByDuration = getSingleChangeGraphData(response, category)

        const singleGraphData = {
            name: "Score",
            type: "bar",
            pointStart: 0,
            data: stockDataByDuration
                .slice(0, stockDataByDuration.length / 2)
                .map((prevIndex, currIndex) => ({
                    y:
                        (prevIndex +
                            stockDataByDuration.slice(
                                stockDataByDuration.length / 2
                            )[currIndex]) /
                        2,
                    stPercent: prevIndex,
                    newsPercent: stockDataByDuration.slice(
                        stockDataByDuration.length / 2
                    )[currIndex]
                })),
            color: "#43a84a",
            negativeColor: "#e64736"
        }

        setBuzzGraphChange(() => {
            return {
                chart: {
                    type: "bar",
                    borderColor: "#c0c0c0",
                    borderWidth: 1.25,
                    borderRadius: 5,
                    styledmode: "on"
                },
                plotOptions: {
                    series: {
                        stacking: "normal",
                        cursor: "pointer",
                        events: {
                            click: function (event) {
                                drawBuzzChangeGraph(
                                    arrB,
                                    tickerList,
                                    toggle,
                                    response
                                )
                            }
                        }
                    }
                },
                legend: {
                    enabled: false
                },
                colors: ["#43a84a", "#f5d561"],
                lang: {
                    thousandsSep: ","
                },
                credits: {
                    text: "finsoftai.com",
                    href: "http://www.finsoftai.com",
                    style: {
                        fontSize: 12
                    },
                    position: {
                        align: "right",
                        x: -10
                    }
                },
                stockTools: {
                    gui: {
                        enabled: false
                    }
                },
                xAxis: [
                    {
                        title: {
                            text: category,
                            style: {
                                fontWeight: "bold",
                                fontSize: "15px",
                                fontFamily: "Poppins"
                            }
                        },
                        categories: SINGLE_GRAPH_DURATION,
                        plotLines: [
                            {
                                value: 4.5,
                                width: 1,
                                color: "#aaa",
                                zIndex: 10
                            }
                        ],
                        labels: {
                            style: {
                                fontFamily: "Poppins",
                                fontWeight: "bold",
                                color: "#A8A8A8"
                            }
                        }
                    }
                ],
                // legend:{enabled:false},
                yAxis: [
                    {
                        title: {
                            text: "Average Change in Social Buzz",
                            style: {
                                fontWeight: "bold",
                                fontSize: "15px",
                                fontFamily: "Poppins"
                            }
                        },
                        labels: {
                            formatter: function () {
                                return this.value + "%"
                            }
                        }
                    }
                ],
                title: {
                    text: ""
                },

                exporting: {
                    buttons: {
                        contextButton: {
                            menuItems: ["viewFullscreen"]
                        }
                    }
                },

                tooltip: {
                    useHTML: true,
                    headerFormat:
                        '<div style="text-align:center;">  {point.x:,.0f} </div>',
                    thousandsSep: ",",
                    pointFormat:
                        '<div style="text-align:center;"><b>Change in Buzz volume : {point.y:,.2f}%</b><p>Social Media : {point.stPercent:,.2f}% | News : {point.newsPercent:,.2f}%</p></div>', // <br></br>Social Media value: {point.stAbs:,.2f} | News value: {point.newsAbs:,.2f}
                    // valueDecimal:1,
                    padding: 4
                },
                series: singleGraphData
            }
        })
    }

    const drawSentimentChangeGraph = (arrB, tickerList, toggle, response) => {
        setSentiGraphChange(() => {
            return {
                chart: {
                    type: "bar",
                    borderColor: "#c0c0c0",
                    borderWidth: 1.25,
                    borderRadius: 5,
                    styledmode: "on"
                },
                plotOptions: {
                    series: {
                        stacking: "normal",
                        cursor: "pointer",
                        events: {
                            click: function (event) {
                                getSingleSentimentChangeGraphView(
                                    event.point,
                                    tickerList,
                                    arrB,
                                    toggle,
                                    response
                                )
                            }
                        }
                    }
                },
                legend: {
                    enabled: false
                },
                lang: {
                    thousandsSep: ","
                },
                credits: {
                    text: "finsoftai.com",
                    href: "http://www.finsoftai.com",
                    style: {
                        fontSize: 12
                    },
                    position: {
                        align: "right",
                        x: -10
                    }
                },
                stockTools: {
                    gui: {
                        enabled: false
                    }
                },
                xAxis: [
                    {
                        title: {
                            text: "Tickers",
                            style: {
                                fontWeight: "bold",
                                fontSize: "15px",
                                fontFamily: "Poppins"
                            }
                        },
                        categories: tickerList[toggle],
                        plotLines: [
                            {
                                value: 4.5,
                                width: 1,
                                color: "#aaa",
                                zIndex: 10
                            }
                        ],
                        labels: {
                            style: {
                                fontFamily: "Poppins",
                                fontWeight: "bold",
                                color: "#A8A8A8"
                            }
                        }
                    }
                ],
                // legend:{enabled:false},
                yAxis: [
                    {
                        title: {
                            text: "Average Change in Social Sentiment Score",
                            style: {
                                fontWeight: "bold",
                                fontSize: "15px",
                                fontFamily: "Poppins"
                            }
                        },
                        labels: {
                            formatter: function () {
                                return this.value + "%"
                            }
                        }
                    }
                ],
                title: {
                    text: ""
                },

                exporting: {
                    buttons: {
                        contextButton: {
                            menuItems: ["viewFullscreen"],
                            className: "step-four"
                        }
                    }
                },
                tooltip: {
                    useHTML: true,
                    headerFormat:
                        '<div style="text-align:center;">  {point.x:,.0f} </div>',
                    thousandsSep: ",",
                    pointFormat:
                        '<div style="text-align:center;"><b>Change in Social Sentiment : {point.y:,.2f}%</b><p>Social Media : {point.stPercent:,.2f}% | News : {point.newsPercent:,.2f}%</p></div>' //<br></br>Social Media value: {point.stAbs:,.3f} | News value: {point.newsAbs:,.3f}
                    // valueDecimal:1,
                    // valueDecimal:1,
                },
                series: arrB
            }
        })
    }

    const getSingleSentimentChangeGraphView = (
        { category },
        tickerList,
        arrB,
        toggle,
        response
    ) => {
        const stockDataByDuration = getSingleChangeGraphData(response, category)

        const singleGraphData = {
            name: "Score",
            type: "bar",
            pointStart: 0,
            data: stockDataByDuration
                .slice(0, stockDataByDuration.length / 2)
                .map((prevIndex, currIndex) => ({
                    y:
                        (prevIndex +
                            stockDataByDuration.slice(
                                stockDataByDuration.length / 2
                            )[currIndex]) /
                        2,
                    stPercent: prevIndex,
                    newsPercent: stockDataByDuration.slice(
                        stockDataByDuration.length / 2
                    )[currIndex]
                })),
            color: "#43a84a",
            negativeColor: "#e64736"
        }

        setSentiGraphChange(() => {
            return {
                chart: {
                    type: "bar",
                    borderColor: "#c0c0c0",
                    borderWidth: 1.25,
                    borderRadius: 5,
                    styledmode: "on"
                },
                plotOptions: {
                    series: {
                        stacking: "normal",
                        cursor: "pointer",
                        events: {
                            click: function (event) {
                                drawSentimentChangeGraph(
                                    arrB,
                                    tickerList,
                                    toggle,
                                    response
                                )
                            }
                        }
                    }
                },
                legend: {
                    enabled: false
                },
                colors: ["#43a84a", "#f5d561"],
                lang: {
                    thousandsSep: ","
                },
                credits: {
                    text: "finsoftai.com",
                    href: "http://www.finsoftai.com",
                    style: {
                        fontSize: 12
                    },
                    position: {
                        align: "right",
                        x: -10
                    }
                },
                stockTools: {
                    gui: {
                        enabled: false
                    }
                },
                xAxis: [
                    {
                        title: {
                            text: category,
                            style: {
                                fontWeight: "bold",
                                fontSize: "15px",
                                fontFamily: "Poppins"
                            }
                        },
                        categories: SINGLE_GRAPH_DURATION,
                        plotLines: [
                            {
                                value: 4.5,
                                width: 1,
                                color: "#aaa",
                                zIndex: 10
                            }
                        ],
                        labels: {
                            style: {
                                fontFamily: "Poppins",
                                fontWeight: "bold",
                                color: "#A8A8A8"
                            }
                        }
                    }
                ],
                yAxis: [
                    {
                        title: {
                            text: "Average Change in Social Sentiment Score",
                            style: {
                                fontWeight: "bold",
                                fontSize: "15px",
                                fontFamily: "Poppins"
                            }
                        },
                        labels: {
                            formatter: function () {
                                return this.value + "%"
                            }
                        }
                    }
                ],
                title: {
                    text: ""
                },

                exporting: {
                    buttons: {
                        contextButton: {
                            menuItems: ["viewFullscreen"]
                        }
                    }
                },

                tooltip: {
                    useHTML: true,
                    headerFormat:
                        '<div style="text-align:center;">  {point.x:,.0f} </div>',
                    thousandsSep: ",",
                    pointFormat:
                        '<div style="text-align:center;"><b>Change in Social Sentiment : {point.y:,.2f}%</b><p>Social Media : {point.stPercent:,.2f}% | News : {point.newsPercent:,.2f}%</p></div>', // <br></br>Social Media value: {point.stAbs:,.2f} | News value: {point.newsAbs:,.2f}
                    // valueDecimal:1,
                    padding: 4
                },
                series: singleGraphData
            }
        })
    }

    // Generic function to fetch graph data
    const fetchGraphData = async (payload, graphType, stateKey) => {
        const response = await getGraphData({
            ...payload,
            graph_type: graphType
        })

        setGraphData((prevGraphData) => ({
            ...prevGraphData,
            [stateKey]: response
        }))

        return response
    }

    // Function to fetch the graph data
    const fetchBuzzGraphData = async (payload) => {
        const response = await getGraphData({
            ...payload,
            graph_type: "social_buzz"
        })

        setGraphData((prevGraphData) => ({
            ...prevGraphData,
            buzz: response
        }))

        return response
    }

    // Function to fetch the sentiment graph data
    const fetchSentimentGraphData = async (payload) => {
        const response = await getGraphData({
            ...payload,
            graph_type: "sentiment"
        })

        setGraphData((prevGraphData) => ({
            ...prevGraphData,
            sentiment: response
        }))

        return response
    }

    // Function to fetch the buzz change graph data
    const fetchBuzzChangeGraphData = async (payload) => {
        const response = await getGraphData({
            ...payload,
            graph_type: "social_buzz_change"
        })

        setGraphData((prevGraphData) => ({
            ...prevGraphData,
            changeBuzz: response
        }))

        return response
    }

    // Function to fetch the sentiment change graph data
    const fetchSentimentChangeGraphData = async (payload) => {
        const response = await getGraphData({
            ...payload,
            graph_type: "sentiment_change"
        })

        setGraphData((prevGraphData) => ({
            ...prevGraphData,
            changeSentiment: response
        }))

        return response
    }

    // Function to process data and draw the graph
    const processAndDrawBuzzGraph = useCallback(
        (response) => {
            const temp = findSeries(graphViews.buzz, response, 0, "buzz")

            let tickerList = sortSeries(
                getFaangStocks(temp["news"]),
                getFaangStocks(temp["st"]),
                "buzz"
            )

            const arr1 = getSeriesTwo(
                getFaangStocks(temp["news"]),
                getFaangStocks(temp["st"]),
                "Score",
                tickerList
            )

            drawBuzzGraph(arr1, tickerList.all, response)
        },
        [graphViews.buzz]
    )

    // Function to process data and draw the sentiment graph
    const processAndDrawSentimentGraph = useCallback(
        (response) => {
            const temp = findSeries(
                graphViews.sentiment,
                response,
                0,
                "sentiment"
            )

            let tickerList = sortSeries(
                getFaangStocks(temp["news"]),
                getFaangStocks(temp["st"]),
                "sentiment"
            )

            let toggle = "all"
            let arr1 = getSeries(
                getFaangStocks(temp["news"]),
                getFaangStocks(temp["st"]),
                null,
                null,
                "Score",
                tickerList
            )

            let arrB = arr1
            drawSentimentGraph(arrB, tickerList, toggle, response)
        },
        [graphViews.sentiment]
    )

    // Function to process data and draw the buzz change graph
    const processAndDrawBuzzChangeGraph = useCallback(
        (response) => {
            const temp = findSeries(
                graphViews.changeBuzz,
                response,
                1,
                "buzz_change"
            )

            let tickerList = sortSeries(
                getFaangStocks(temp["news"]),
                getFaangStocks(temp["st"]),
                "buzzChange"
            )

            let toggle = "all"

            let arr1 = getSeries(
                getFaangStocks(temp["news"]),
                getFaangStocks(temp["st"]),
                getFaangStocks(temp["newsAbs"]),
                getFaangStocks(temp["stAbs"]),
                "Score",
                tickerList
            )

            let arrBTWO = arr1
            drawBuzzChangeGraph(arrBTWO, tickerList, toggle, response)
        },
        [graphViews.changeBuzz]
    )

    // Function to process data and draw the sentiment change graph
    const processAndDrawSentimentChangeGraph = useCallback(
        (response) => {
            const temp = findSeries(graphViews.changeSentiment, response, 1)

            let tickerList = sortSeries(
                getFaangStocks(temp["news"]),
                getFaangStocks(temp["st"]),
                "sentimentChange"
            )

            let toggle = "all"

            let arr1 = getSeries(
                getFaangStocks(temp["news"]),
                getFaangStocks(temp["st"]),
                getFaangStocks(temp["newsAbs"]),
                getFaangStocks(temp["stAbs"]),
                "Score",
                tickerList
            )

            let arrB = arr1
            drawSentimentChangeGraph(arrB, tickerList, toggle, response)
        },
        [graphViews.changeSentiment]
    )

    useGraphUpdate(graphData, graphViews, "buzz", processAndDrawBuzzGraph)
    useGraphUpdate(
        graphData,
        graphViews,
        "sentiment",
        processAndDrawSentimentGraph
    )
    useGraphUpdate(
        graphData,
        graphViews,
        "changeBuzz",
        processAndDrawBuzzChangeGraph
    )
    useGraphUpdate(
        graphData,
        graphViews,
        "changeSentiment",
        processAndDrawSentimentChangeGraph
    )

    // Function to render Alerts
    const renderAlerts = (response) => {
        const alerts = generateAlerts(response)
        setAlerts(alerts)
    }

    const getGraphs = async () => {
        if (!isLoading) {
            setIsLoading(true)

            const date = graphDateTime
                ? dayjs(graphDateTime).format("YYYY-MM-DDTHH:mm:ssZ")
                : null

            const payload = {
                date,
                marketStatusDate: date,
                marketStatusKey
            }

            let rawResponse = {}

            const buzzGraphData = await fetchGraphData(
                payload,
                "social_buzz",
                "buzz"
            )
            processAndDrawBuzzGraph(buzzGraphData)
            rawResponse = { ...rawResponse, buzz: buzzGraphData }

            const sentimentGraphData = await fetchGraphData(
                payload,
                "sentiment",
                "sentiment"
            )
            processAndDrawSentimentGraph(sentimentGraphData)
            rawResponse = { ...rawResponse, sentiment: sentimentGraphData }

            const buzzChangeGraphData = await fetchGraphData(
                payload,
                "social_buzz_change",
                "changeBuzz"
            )
            processAndDrawBuzzChangeGraph(buzzChangeGraphData)
            rawResponse = { ...rawResponse, changeBuzz: buzzChangeGraphData }

            const sentimentChangeGraphData = await fetchGraphData(
                payload,
                "sentiment_change",
                "changeSentiment"
            )
            processAndDrawSentimentChangeGraph(sentimentChangeGraphData)
            rawResponse = {
                ...rawResponse,
                changeSentiment: sentimentChangeGraphData
            }

            renderAlerts(rawResponse)
        }

        setIsLoading(false)
    }

    useEffect(() => {
        getGraphs()
    }, [graphDateTime, marketStatusKey])

    const handleGetGraphWithDate = async () => {
        await getGraphs()

        setDateChangeModal(() => {
            return false
        })
    }

    const buzzGraphRef = useMemo(() => {
        return buzzGraph
    }, [buzzGraph])

    useEffect(() => {
        if (props.modalToggle) {
            setGraphToggle(() => {
                return true
            })
        }
    }, [props.modalToggle])
    useEffect(() => {
        props.setModalToggle(graphModalToggle)
    }, [graphModalToggle])

    useEffect(() => {
        setViews(1, "one_day")
        setViews(2, "one_day")
        setViews(3, "one_day")
        setViews(4, "one_day")
        let newDate = new Date()
        let date = newDate.getDate()
        setUpdatedDate(() => {
            return Moment(newDate).format("DD/MMMM/YYYY")
        })
    }, [datesList])

    useEffect(() => {
        if (loading) return
        if (!user) return history.push("/")
        // fetchUserName();
    }, [user, loading])

    useEffect(() => {
        setTimeout(() => {
            console.log("Timer ends")
            logout()
        }, 30 * 60 * 1000)
    }, [])

    const selectMarketStatus = (marketStatuskey) => {
        setMarketStatusKey(marketStatuskey ?? "all")
    }

    return (
        <div>
            <Joyride
                steps={steps}
                run={run}
                scrollToFirstStep={true}
                showProgress={false}
                continuous={true}
                callback={handleJoyrideClose}
                stepIndex={stepIndex}
                disableCloseOnEsc
                disableOverlayClose
                styles={{
                    options: {
                        primaryColor: "#2a6ba6",
                        padding: "5px"
                    },
                    tooltipContent: {
                        padding: "15px 10px 2px 10px"
                    }
                }}
            />
            <div
                classes='container-fluid'
                className={classes.paper}
                style={{
                    maxWidth: "100%",
                    maxHeight: "100%",
                    padding: 0,
                    overflowX: "clip"
                }}>
                <div
                    className='row  justify-content-center'
                    style={{
                        background:
                            "linear-gradient(268deg, rgba(11, 132, 222, 0.13) 0%, rgba(11, 132, 222, 0.16) 0.01%, rgba(87, 178, 245, 0.04) 100%)"
                    }}>
                    <div
                        className='col-md-6 justify-content-start col-sm-12 mt-3'
                        style={{
                            textAlign: "left",
                            paddingLeft: "5%",
                            paddingBottom: 10
                        }}>
                        <img
                            src={FSAILogo}
                            alt='LOGO'
                            width={228}
                            height={58}></img>
                    </div>
                    <div className='col-md-6 mt-2 col-sm-12'>
                        <div style={{ width: "100%" }}>
                            {/* <Typography align='left'> Share on: </Typography> */}

                            <Stack
                                direction='row'
                                spacing={1}
                                justifyContent='flex-end'
                                style={{ width: "100%" }}>
                                <Typography
                                    className='mt-4'
                                    style={{ fontFamily: "Poppins" }}>
                                    Share:
                                </Typography>

                                {actions.map((action) => {
                                    return action.icon
                                })}

                                <div>
                                    <IconButton
                                        size='medium'
                                        className='mt-2'
                                        onClick={() => {
                                            logout()
                                        }}
                                        sx={{ paddingLeft: 0 }}>
                                        <LogoutRounded fontSize='medium' />
                                    </IconButton>
                                </div>
                            </Stack>
                        </div>
                    </div>
                    <div>
                        <Modal
                            aria-labelledby='transition-modal-title'
                            aria-describedby='transition-modal-description'
                            className={classes.modal}
                            open={dateChangeModal}
                            //  keepMounted
                            onClose={() => {
                                setDateChangeModal(() => {
                                    return false
                                })
                            }}
                            closeAfterTransition
                            BackdropComponent={Backdrop}
                            BackdropProps={{
                                timeout: 500
                            }}>
                            <div
                                classes='container-fluid '
                                className={classes.paper}
                                style={{ maxWidth: "70%" }}>
                                <div className='row'>
                                    <h2
                                        className='col-10 transition-modal-description'
                                        style={{ textAlign: "left" }}>
                                        Set ET Date & Time
                                    </h2>
                                    <div className='row'>
                                        <LocalizationProvider
                                            dateAdapter={AdapterDayjs}>
                                            <DemoContainer
                                                components={["DateTimePicker"]}>
                                                <DateTimePicker
                                                    timezone='America/New_York'
                                                    value={graphDateTime}
                                                    onChange={(newValue) => {
                                                        checkFutureData(
                                                            newValue
                                                        )
                                                    }}
                                                />
                                            </DemoContainer>
                                        </LocalizationProvider>
                                    </div>
                                    <div className='row justify-content-center mt-3'>
                                        <Button
                                            className='col-md-4 confirm-btn'
                                            style={{
                                                backgroundColor: "#0d6efd",
                                                color: "white"
                                            }}
                                            size='small'
                                            variant='contained'
                                            onClick={handleGetGraphWithDate}>
                                            Confirm
                                        </Button>
                                    </div>
                                </div>
                            </div>
                        </Modal>
                    </div>
                    <hr
                        style={{
                            background:
                                "linear-gradient(268deg, rgba(11, 132, 222, 0.13) 0%, rgba(11, 132, 222, 0.16) 0.01%, rgba(87, 178, 245, 0.04) 100%)"
                        }}></hr>
                    <VideoSection> </VideoSection>
                </div>
                <Accordion>
                    <AccordionSummary
                        className=''
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls='panel1a-content'
                        id='panel1a-header'>
                        <div className='text-black text-xl font-bold'>
                            Today's Alert
                        </div>
                    </AccordionSummary>
                    ˚
                    <AccordionDetails>
                        <DashboardAlert date={displayDate} alerts={alerts} />
                    </AccordionDetails>
                </Accordion>

                <div className='flex justify-content-start items-center my-2 step-one px-3'>
                    <div className='flex flex-col justify-content-start col-md-5 col-sm-12 gap-1'>
                        <h2
                            className='col-md-12 text-md-start text-lg-start'
                            style={{
                                color: "#1F73C1",
                                fontFamily: "Poppins",
                                fontWeight: 500,
                                fontSize: 26
                            }}>
                            FAANG Sentiment Dashboards
                        </h2>
                        <div
                            className='col-md-12 col-sm-12 text-md-start text-lg-start'
                            style={{
                                color: "grey",
                                fontSize: 20,
                                fontFamily: "Poppins",
                                textAlign: "left"
                            }}>
                            <span>
                                * As on Morning (Pre-Market):
                                <Button
                                    variant='text'
                                    style={{ color: "grey", fontSize: 16 }}
                                    size='medium'
                                    onClick={() => {
                                        setDateChangeModal(() => {
                                            return true
                                        })
                                    }}>
                                    {" "}
                                    {graphDateTime
                                        ? dayjs(graphDateTime).format(
                                              "DD-MMM-YYYY"
                                          ) + " ET"
                                        : displayDate + ""}{" "}
                                </Button>
                            </span>
                        </div>
                    </div>

                    <div className='flex justify-between items-end col-md-7 col-sm-12 flex gap-4'>
                        <div className='flex flex-col justify-center'>
                            <p className='text-base font-medium'>
                                Market timing Mode
                            </p>
                            <Switcher
                                items={marketStatusItems}
                                onSelect={selectMarketStatus}
                                selected={marketStatusKey}
                            />
                        </div>
                        <a
                            href='https://finsoftai-faang.streamlit.app/'
                            target='_blank'
                            rel='noreferrer'>
                            <Button
                                variant='contained'
                                style={{
                                    backgroundColor: "rgb(75, 143, 205)",
                                    color: "white",
                                    fontSize: 16
                                }}
                                size='medium'>
                                Ask FAANG Bot
                            </Button>
                        </a>
                        <div>
                            <IconButton
                                size='small'
                                variant='outlined'
                                style={{
                                    height: "50%",
                                    width: "100%",
                                    color: "#474747",
                                    borderRadius: 10,
                                    padding: 8,
                                    fontWeight: 600,
                                    fontSize: 18,
                                    backgroundColor: "#f9ce1b",
                                    boxShadow: "2px 2px lightgrey"
                                }}
                                className='mr-2'
                                onClick={() => {
                                    setRun(() => {
                                        return true
                                    })
                                }}
                                sx={{ paddingLeft: 0, paddingRight: 2 }}>
                                Take a Tour
                                <InfoTwoToneIcon
                                    fontSize='medium'
                                    className='ml-2'
                                />
                            </IconButton>
                        </div>
                    </div>
                </div>
                <hr style={{ marginTop: 0 }}></hr>
                <div
                    className='row justify-content-center'
                    style={{
                        fontFamily: "Poppins",
                        fontSize: "1.1rem",
                        fontWeight: 400,
                        width: "100%",
                        padding: 0,
                        marginRight: 0,
                        marginLeft: 0
                    }}>
                    <Grid
                        container
                        rowSpacing={1}
                        columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
                        {/* first */}
                        <Grid item md={6} xs={12}>
                            <div style={{ padding: 5 }}>
                                <FullScreen
                                    handle={screen1}
                                    onChange={getGraphs}>
                                    <Card
                                        sx={{ boxShadow: "none" }}
                                        style={
                                            screen1.active
                                                ? {
                                                      borderRadius: 20,
                                                      backgroundColor:
                                                          "#f4f4f4",
                                                      height: "100%"
                                                  }
                                                : {
                                                      borderRadius: 20,
                                                      backgroundColor: "white"
                                                  }
                                        }>
                                        <CardHeader
                                            title={
                                                <div
                                                    className='row'
                                                    style={{
                                                        padding:
                                                            "0px !important",
                                                        paddingTop: 5,
                                                        paddingBottom: 5,
                                                        textAlign: "left"
                                                    }}>
                                                    <h4
                                                        className='col-md-5'
                                                        style={{
                                                            fontFamily:
                                                                "Poppins",
                                                            color: "#222222"
                                                        }}>
                                                        Social Buzz
                                                    </h4>
                                                    <div
                                                        className='col-md-6'
                                                        style={{
                                                            textAlign: "end"
                                                        }}>
                                                        {topBar(1)}{" "}
                                                    </div>
                                                </div>
                                            }
                                        />
                                        <CardContent>
                                            {/* {} */}

                                            <HighchartsReact
                                                highcharts={Highcharts}
                                                options={buzzGraphRef}
                                            />
                                        </CardContent>
                                    </Card>
                                </FullScreen>
                            </div>
                        </Grid>
                        {/* second */}
                        <Grid item md={6} xs={12}>
                            <div style={{ padding: 5 }}>
                                <FullScreen
                                    handle={screen2}
                                    onChange={getGraphs}>
                                    <Card
                                        sx={{ boxShadow: "none" }}
                                        style={
                                            screen2.active
                                                ? {
                                                      borderRadius: 20,
                                                      backgroundColor:
                                                          "#f4f4f4",
                                                      height: "100%",
                                                      overflowY: "scroll"
                                                  }
                                                : {
                                                      borderRadius: 20,
                                                      backgroundColor: "white"
                                                  }
                                        }>
                                        <CardHeader
                                            title={
                                                <div
                                                    className='row'
                                                    style={{
                                                        padding:
                                                            "0px !important",
                                                        paddingTop: 5,
                                                        paddingBottom: 5,
                                                        textAlign: "left"
                                                    }}>
                                                    <h4
                                                        className='col-md-5'
                                                        style={{
                                                            fontFamily:
                                                                "Poppins",
                                                            color: "#222222"
                                                        }}>
                                                        Social Sentiment
                                                    </h4>
                                                    {/* <Chip style={{ fontSize: 14, fontWeight: 500 }} className="col-md-auto ml-2" label={displayDates["sentiment"]} /> */}
                                                    <div className='col-md-6'>
                                                        {" "}
                                                        {topBar(2)}{" "}
                                                    </div>
                                                </div>
                                            }
                                        />
                                        <CardContent>
                                            <HighchartsReact
                                                highcharts={Highcharts}
                                                options={sentiGraph}
                                            />
                                        </CardContent>
                                    </Card>
                                </FullScreen>
                            </div>
                        </Grid>
                        <Grid item md={12} xs={12}>
                            <hr></hr>
                        </Grid>
                        {/* third */}
                        <Grid item md={6} xs={12}>
                            <div style={{ padding: 5 }}>
                                <FullScreen
                                    handle={screen3}
                                    onChange={getGraphs}>
                                    <Card
                                        sx={{ boxShadow: "none" }}
                                        style={
                                            screen3.active
                                                ? {
                                                      borderRadius: 20,
                                                      backgroundColor:
                                                          "#f4f4f4",
                                                      height: "100%",
                                                      overflowY: "scroll"
                                                  }
                                                : {
                                                      borderRadius: 20,
                                                      backgroundColor: "white"
                                                  }
                                        }>
                                        <CardHeader
                                            title={
                                                <div
                                                    className='row'
                                                    style={{
                                                        padding:
                                                            "0px !important",
                                                        textAlign: "left"
                                                    }}>
                                                    <h4
                                                        className='col-md-6'
                                                        style={{
                                                            fontFamily:
                                                                "Poppins",
                                                            color: "#222222"
                                                        }}>
                                                        Change in Social Buzz
                                                    </h4>
                                                    {/* <Chip style={{ fontSize: 14, fontWeight: 500 }} className="col-md-auto ml-2" label={displayDates["changeBuzz"]} /> */}
                                                    <div className='col-md-6'>
                                                        {topBar(3)}
                                                    </div>
                                                </div>
                                            }
                                        />
                                        <CardContent>
                                            <HighchartsReact
                                                highcharts={Highcharts}
                                                options={buzzGraphChange}
                                            />
                                        </CardContent>
                                    </Card>
                                </FullScreen>
                            </div>
                        </Grid>
                        {/* fourth */}
                        <Grid item md={6} xs={12}>
                            <div style={{ padding: 5 }} className='step-two'>
                                <FullScreen
                                    handle={screen4}
                                    onChange={getGraphs}>
                                    <Card
                                        sx={{ boxShadow: "none" }}
                                        style={
                                            screen4.active
                                                ? {
                                                      borderRadius: 20,
                                                      backgroundColor:
                                                          "#f4f4f4",
                                                      height: "100%",
                                                      overflowY: "scroll"
                                                  }
                                                : {
                                                      borderRadius: 20,
                                                      backgroundColor: "white"
                                                  }
                                        }>
                                        <CardHeader
                                            title={
                                                <div
                                                    className='row p-0'
                                                    style={{
                                                        padding:
                                                            "0px !important",
                                                        paddingTop: 5,
                                                        paddingBottom: 5,
                                                        textAlign: "left"
                                                    }}>
                                                    <h4
                                                        className='col-md-auto'
                                                        style={{
                                                            fontFamily:
                                                                "Poppins",
                                                            color: "#222222"
                                                        }}>
                                                        Change in Social
                                                        Sentiment
                                                    </h4>
                                                    {/* <Chip style={{ fontSize: 14, fontWeight: 500 }} className="col-md-auto ml-2" label={displayDates["changeSentiment"]} /> */}
                                                    <div className='col-md-auto step-three'>
                                                        {" "}
                                                        {topBar(4)}{" "}
                                                    </div>
                                                </div>
                                            }
                                        />
                                        <CardContent className='step-five'>
                                            <HighchartsReact
                                                highcharts={Highcharts}
                                                options={sentiGraphChange}
                                            />
                                        </CardContent>
                                    </Card>
                                </FullScreen>
                            </div>
                        </Grid>
                        {/* <hr className='mt-4 mb-2' ></hr> */}
                        <Grid item md={12} xs={12}>
                            <div className='step-six'>
                                <Wordcloud
                                    date={
                                        graphDateTime
                                            ? dayjs(graphDateTime).format(
                                                  "YYYY-MM-DDTHH:mm:ssZ"
                                              )
                                            : null
                                    }
                                />
                            </div>
                        </Grid>
                        <Grid item md={12} xs={12}>
                            <ProductSheets />
                        </Grid>
                        <Grid item md={12} xs={12}>
                            <Footer />
                        </Grid>
                    </Grid>
                </div>
            </div>
        </div>
    )
}

export default withRouter(Graphs)
