import api from "../components/api_ui"
import socialBuzzJSON from "../components/static/data/social_buzz.json"
import sentimentJSON from "../components/static/data/sentiment.json"
import socialBuzzChangeJSON from "../components/static/data/social_buzz_change.json"
import sentimentChangeJSON from "../components/static/data/sentiment_change.json"
import wordCloudJSON from "../components/static/data/wordcloud.json"
import onMarketStatusSocialBuzzJSON from "../components/static/data/on_market_status_social_buzz.json"
import onMarketStatusSentimentJSON from "../components/static/data/on_market_status_sentiment.json"
import onMarketStatusSocialBuzzChangeJSON from "../components/static/data/on_market_status_social_buzz_change.json"
import onMarketStatusSentimentChangeJSON from "../components/static/data/on_market_status_sentiment_change.json"
import offMarketStatusSocialBuzzJSON from "../components/static/data/off_market_status_social_buzz.json"
import offMarketStatusSentimentJSON from "../components/static/data/off_market_status_sentiment.json"
import offMarketStatusSocialBuzzChangeJSON from "../components/static/data/off_market_status_social_buzz_change.json"
import offMarketStatusSentimentChangeJSON from "../components/static/data/off_market_status_sentiment_change.json"

const getGraphData = async ({
    graph_type,
    date,
    ticker,
    marketStatusDate,
    marketStatusKey
}) => {
    let response

    // If Market Status with Date
    if (
        date &&
        marketStatusDate &&
        (marketStatusKey === "on" || marketStatusKey === "off")
    ) {
        response = await api.get_market_status_graphs({
            graph_type,
            date,
            marketStatusDate,
            market: marketStatusKey
        })

        try {
            const jsonContent = response.replace(/null/g, "0")

            response = JSON.parse(jsonContent).data
        } catch (e) {
            response = response.data
        }

        return response
    }

    // If Date exists
    else if (date) {
        response = await api.get_graphs(graph_type, date, ticker)

        try {
            if (graph_type === "wordcloud") {
                let wordCloud = {}
                wordCloud[ticker] = response

                return wordCloud
            } else {
                const jsonContent = response.replace(/null/g, "0")

                response = JSON.parse(jsonContent).data
            }
        } catch (e) {
            response = response.data
        }

        return response
    }

    // If Market Status exist
    else if (marketStatusKey) {
        if (marketStatusKey === "on") {
            if (graph_type === "social_buzz")
                return onMarketStatusSocialBuzzJSON
            else if (graph_type === "sentiment")
                return onMarketStatusSentimentJSON
            else if (graph_type === "social_buzz_change")
                return onMarketStatusSocialBuzzChangeJSON
            else if (graph_type === "sentiment_change")
                return onMarketStatusSentimentChangeJSON
        } else if (marketStatusKey === "off") {
            if (graph_type === "social_buzz")
                return offMarketStatusSocialBuzzJSON
            else if (graph_type === "sentiment")
                return offMarketStatusSentimentJSON
            else if (graph_type === "social_buzz_change")
                return offMarketStatusSocialBuzzChangeJSON
            else if (graph_type === "sentiment_change")
                return offMarketStatusSentimentChangeJSON
        }
    }

    if (graph_type === "social_buzz") return socialBuzzJSON
    else if (graph_type === "sentiment") return sentimentJSON
    else if (graph_type === "social_buzz_change") return socialBuzzChangeJSON
    else if (graph_type === "sentiment_change") return sentimentChangeJSON
    else if (graph_type === "wordcloud") return wordCloudJSON
}

export { getGraphData }
