import AlertCardContainer from "../page/Alert/AlertCardContainer"
import { alertConfig } from "../../constants"
import AlertParameter from "../page/Alert/AlertParameter"

const DashboardAlert = ({ date, alerts }) => {
    if (!alerts.momentumAlerts.length && !alerts.preMarketAlerts.length) return

    return (
        <section className='w-11/12 justify-center m-auto flex'>
            <div className='flex-col justify-start items-center gap-8 flex'>
                <div className='justify-start items-center gap-14 flex flex-wrap'>
                    <div className='flex-col justify-start items-center gap-2.5 flex'>
                        <div className='text-black text-xl font-bold'>
                            Today's Alert
                        </div>
                        <div className='text-black text-xs font-medium'>
                            {date} ET
                        </div>
                    </div>
                    <div className='gap-6 flex flex-wrap flex-col'>
                        <AlertCardContainer
                            alerts={alerts.preMarketAlerts}
                            index={0}
                        />
                        <AlertCardContainer
                            alerts={alerts.momentumAlerts}
                            index={1}
                        />
                    </div>
                </div>
                <div className='justify-start items-start gap-10 flex'>
                    {alertConfig.params.map((params, key) => {
                        return <AlertParameter key={key} {...params} />
                    })}
                </div>
            </div>
        </section>
    )
}

export default DashboardAlert
